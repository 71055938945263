import * as React from 'react'

import { Link } from 'elements/Link/Link'
import { Icon } from 'elements/Icon/Icon'

export enum TextLinkTheme {
  LIGHT = 'lightTheme',
  DARK = 'darkTheme',
}

export type Props = {
  children: React.ReactNode
  href?: string
  iconLeft?: boolean
  iconSize?: number
  disabled?: boolean
  theme?: TextLinkTheme
  iconClass?: string
  className?: string
}

const classes = {
  lightTheme: `text-white`,
  darkTheme: `text-grey-dark hover:text-black-light`,
}

export const TextLink = ({
  children,
  href,
  iconLeft,
  iconSize,
  disabled = false,
  theme = TextLinkTheme.DARK,
  iconClass = '',
  className = '',
}: Props) => {
  const icon = (
    <Icon
      name={'arrow-right'}
      className={`inline w-4 ${iconLeft ? 'mr-4' : 'ml-2'} ${iconClass}`}
      size={iconSize}
    />
  )

  if (!href || disabled)
    return (
      <span
        className={`animate-arrow xl:animate-arrow-large inline-flex w-auto cursor-pointer items-center uppercase active:underline disabled:cursor-default disabled:no-underline disabled:opacity-50 ${classes[theme]} ${className}`}
      >
        {iconLeft && icon}
        {children}
        {!iconLeft && icon}
      </span>
    )

  return (
    <Link
      href={href}
      className={`animate-arrow xl:animate-arrow-large inline-flex w-auto cursor-pointer items-center uppercase active:underline disabled:cursor-default disabled:no-underline disabled:opacity-50 ${classes[theme]} ${className}`}
    >
      {iconLeft && icon}
      {children}
      {!iconLeft && icon}
    </Link>
  )
}
