import { useState, useRef } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { Accordion, AccordionButton, AccordionItem, AccordionPanel } from '@reach/accordion'
import { Icon } from 'elements/Icon/Icon'
import { useMedia } from 'hooks/useMedia'
import { FilterType } from 'typings/api'

export type FilterProps = {
  filter: FilterType
  handleFilters: (arg1: string, arg2: string) => void
  resetFilters: () => void
  hasNoFilters: boolean
}

export const Filter = ({ filter, handleFilters, resetFilters, hasNoFilters }: FilterProps) => {
  const [openFilterMobile, setOpenFilterMobile] = useState(false)
  const [openFilterDesktop, setOpenFilterDesktop] = useState(false)
  const [openIndexDesktop, setOpenIndexDesktop] = useState(null)
  const [indices, setIndices] = useState([])
  const isDesktop = useMedia('md')
  const { t } = useTranslation()
  const formRef = useRef<HTMLFormElement>()

  const toggleItem = (toggledIndex: number) => {
    if (indices.includes(toggledIndex)) {
      setIndices(indices.filter((currentIndex) => currentIndex !== toggledIndex))
    } else {
      setIndices([...indices, toggledIndex].sort())
    }
  }

  const handleDesktopToggleState = (i: number) => {
    setOpenFilterDesktop((prev) => !prev)
    setOpenIndexDesktop(i)
  }

  const handleReset = () => {
    resetFilters()
    Array.from(document?.getElementsByClassName('filterInput')).forEach(
      (inputField: HTMLInputElement) => {
        inputField.checked = false
        inputField.disabled = false
      },
    )
    setOpenFilterDesktop(false)
  }

  const ResetButton = () => {
    return (
      <button
        disabled={hasNoFilters}
        type="button"
        onClick={handleReset}
        className={clsx(
          'group flex items-center text-xs font-bold underline duration-200 ease-in-out disabled:opacity-30',
        )}
      >
        <Icon
          name="cross"
          className="mr-2 h-5 w-5 duration-200 ease-in-out group-disabled:opacity-30"
        />
        {t('Remove Filter')}
      </button>
    )
  }

  return isDesktop ? (
    <div className="mb-4 items-center bg-gray-light px-10 py-6">
      <menu className="flex w-full items-center gap-8 text-black-light">
        <li>
          <label className="mb-0 text-xl font-bold">{t('Filter')}</label>
        </li>
        <form className="mr-auto flex items-center gap-6" ref={formRef}>
          {filter?.map((item, i) => {
            return (
              <ul key={`${item.key}_${i}`}>
                <li className="relative">
                  <button
                    type="button"
                    disabled={!item?.values?.length}
                    onClick={() => handleDesktopToggleState(i)}
                    className="group relative flex items-center gap-1 font-medium duration-200 ease-in-out hover:text-red disabled:text-gray-medium"
                  >
                    <Icon
                      name="chevron-down"
                      className={clsx(
                        'mr-2 mt-1 transform text-black-light duration-150 ease-in-out group-disabled:text-gray-medium',
                        openFilterDesktop && openIndexDesktop === i ? 'rotate-180' : 'rotate-0',
                      )}
                    />
                    {item.label}
                  </button>
                  <menu
                    className={clsx(
                      'absolute top-full z-10 mt-6 origin-top bg-gray-light p-5 duration-200',
                      openFilterDesktop && openIndexDesktop === i
                        ? 'scale-y-100 shadow-md'
                        : 'scale-y-0',
                    )}
                  >
                    {item.values.map((filterItem, i) => {
                      return (
                        <li key={`${filterItem.label}_${i}`} className="flex items-center py-2">
                          <input
                            type="checkbox"
                            id={filterItem.key}
                            value={filterItem.label}
                            name={item.key}
                            className="filterInput peer z-10 mr-2 h-5 w-5 appearance-none rounded-[1px] border-[1.25px] border-gray-medium bg-transparent
											checked:border-black-light hover:cursor-pointer disabled:cursor-default disabled:opacity-40"
                            onChange={() => {
                              handleFilters(item.key, filterItem.key)
                            }}
                          />
                          <Icon
                            name="checkmark"
                            className="invisible absolute h-5 w-5 p-[1px] text-white peer-checked:visible peer-checked:bg-gray-dark"
                          />
                          <label
                            htmlFor={filterItem.key}
                            className="flex cursor-pointer items-center whitespace-nowrap transition-colors duration-200 peer-checked:text-red peer-hover:text-red peer-disabled:cursor-default peer-disabled:text-black-light peer-disabled:opacity-40"
                          >
                            {filterItem.label}
                          </label>
                        </li>
                      )
                    })}
                  </menu>
                </li>
              </ul>
            )
          })}
        </form>
        <ResetButton />
      </menu>
    </div>
  ) : (
    <div className={clsx('relative z-10', openFilterMobile && 'shadow-lg')}>
      <div className="flex flex-row bg-gray-light">
        <button
          onClick={() => setOpenFilterMobile((prev) => !prev)}
          className="flex flex-1 items-center justify-center py-4 font-bold text-gray-dark"
        >
          {t('Filter')}
          <Icon name="filter" className="ml-2" />
        </button>
      </div>
      {openFilterMobile ? (
        <form
          ref={formRef}
          className={clsx(
            'absolute top-full w-full bg-gray-light px-5 pb-10 ',
            openFilterMobile && 'shadow-lg',
          )}
        >
          <div className="relative py-8">
            <Accordion onChange={toggleItem} index={indices}>
              {filter?.map((item) => {
                if (item.values.length)
                  return (
                    <AccordionItem className="overflow-hidden">
                      <AccordionButton>
                        <h3 className="mb-0 flex flex-row items-center text-base font-bold text-black-light">
                          <div className="mr-3 ml-1">
                            <Icon name="chevron-down" size={12} className="text-black-light" />
                          </div>
                          {item.label}
                        </h3>
                      </AccordionButton>
                      <AccordionPanel>
                        <fieldset>
                          <ul className="mt-2 pl-7">
                            {item.values.map((filterItem, i) => (
                              <li
                                key={`${filterItem.label}_${i}`}
                                className="flex items-center py-2"
                              >
                                <input
                                  type="checkbox"
                                  id={filterItem.key}
                                  value={filterItem.label}
                                  name={item.key}
                                  className="filterInput peer z-10 mr-2 h-5 w-5 appearance-none rounded-[1px] border-[1.25px] border-gray-medium bg-transparent
											checked:border-black-light hover:cursor-pointer disabled:cursor-default disabled:opacity-40"
                                  onChange={() => {
                                    handleFilters(item.key, filterItem.key)
                                  }}
                                />
                                <Icon
                                  name="checkmark"
                                  className="invisible absolute h-5 w-5 p-[1px] text-white peer-checked:visible peer-checked:bg-gray-dark"
                                />
                                <label
                                  htmlFor={filterItem.key}
                                  className="flex cursor-pointer items-center whitespace-nowrap transition-colors duration-200 peer-checked:text-red peer-hover:text-red peer-disabled:cursor-default peer-disabled:text-black-light peer-disabled:opacity-40"
                                >
                                  {filterItem.label}
                                </label>
                              </li>
                            ))}
                          </ul>
                        </fieldset>
                      </AccordionPanel>
                    </AccordionItem>
                  )
              })}
            </Accordion>
          </div>
          <ResetButton />
        </form>
      ) : null}
    </div>
  )
}
