import createDOMPurify, { Config, DOMPurifyI } from 'dompurify'
import { JSDOM } from 'jsdom'

export const htmlContent = (dirty: string = '', options?: Config) => {
  let DOMPurify: DOMPurifyI
  if (typeof window === 'undefined') {
    DOMPurify = createDOMPurify(new JSDOM('').window as unknown as Window)
  } else {
    DOMPurify = createDOMPurify(window)
  }

  DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    // set all elements owning target to target=_blank
    if ('target' in node) {
      node.setAttribute('target', '_blank')
      node.setAttribute('rel', 'noopener noreferrer')
    }
  })

  return DOMPurify.sanitize(dirty, options).toString()
}
