type Props = { className?: string; fullsize?: boolean }

export const Spinner = ({ className, fullsize }: Props) => {
  const Inner = () => (
    <div className={`spinner ${className}`}>
      <div className="double-bounce1"></div>
      <div className="double-bounce2"></div>
    </div>
  )

  if (fullsize) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Inner />
      </div>
    )
  }

  return <Inner />
}
