import { Link } from 'elements/Link/Link'
import { Icon } from 'elements/Icon/Icon'
import clsx from 'clsx'

type Props = {
  breadcrumbData: { href: string; text: string }[] 
  pageTitle: string
  className?: string
}

export const Breadcrumb = ({ breadcrumbData, pageTitle, className }: Props) => {
  return (
    <ul
      className={clsx(
        'px-4 mt-4 mb-8 flex flex-row items-center text-xs leading-5 text-gray-dark md:mx-20 md:text-base md:leading-4 flex-wrap',
        className,
      )}
    >
      {breadcrumbData.map((breadcrumb) => (
        <li key={breadcrumb.href} className="mr-1 md:mr-2">
          <Link
            href={breadcrumb.href}
            className="flex flex-row items-center text-xs md:text-base font-bold text-red hover:underline"
          >
            {breadcrumb.text}
            <Icon name="arrow-right" size={12} className="ml-1 md:ml-2" />
          </Link>
        </li>
      ))}
      <li className="mr-1 text-xs md:text-base font-medium md:mr-2">{pageTitle}</li>
    </ul>
  )
}
