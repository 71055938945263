import { Headline, HeadlineType } from 'elements/Headline/Headline'
import { NextPage } from 'next'
import { PageType } from 'typings/api'
import Image from '@next/image'
import { htmlContent } from 'lib/htmlContent'
import { useTranslation } from 'next-i18next'
import { Icon } from 'elements/Icon/Icon'
import { usePIMData } from '~/hooks/usePIMData'
import invariant from 'tiny-invariant'
import { Breadcrumb } from 'elements/Breadcrumb/Breadcrumb'
import { useEffect, useState } from 'react'
import { Spinner } from '../Spinner/Spinner'
import { NextSeo } from 'next-seo'
import { Filter } from './Filter'

type Props = { urlKey: string }

export const Downloads: NextPage<Props> = ({ urlKey }) => {
  const { t } = useTranslation()
  const { data, isLoading } = usePIMData<PageType>(urlKey)
  invariant(data.type === 'downloads', 'Expected type to be "downloads"')
  const [userFilter, setUserFilter] = useState(Array(data.main.filter.length).fill([]))
  const hasNoFilters = userFilter?.every((e) => e.length === 0)

  if (isLoading) {
    return <Spinner fullsize />
  }

  const handleFilters = (key: string, value: string) => {
    data.main.filter.forEach((item, i) => {
      if (item.key === key) {
        setUserFilter(
          userFilter.map((e, index) => {
            if (i === index && !userFilter[i].includes(value)) {
              return [...userFilter[i], value]
            } else if (i === index) {
              userFilter[i].splice(userFilter[i].indexOf(value), 1)
              return userFilter[i]
            } else return e
          }),
        )
      }
    })

    const changedFilterGroup = key

    const filterInputs = Array.from(document?.getElementsByClassName('filterInput'))

    const checkedFieldKeys = filterInputs
      .filter((filterInput: HTMLInputElement) => filterInput.checked)
      .map((checkedField) => checkedField.id)

    const checkedFieldGroups = filterInputs
      .filter((filterInput: HTMLInputElement) => filterInput.checked)
      .map((checkedField: HTMLInputElement) => checkedField.name)
    const cleanedCheckedFieldGroups = [...new Set(checkedFieldGroups)]

    //runs on every filter Input Field:
    filterInputs.forEach((inputField: HTMLInputElement) => {
      const currentInputFieldKey = inputField.id
      const currentInputFieldGroup = inputField.name

      //runs on every possible filter combination:
      for (let index = 0; index < data.main.assets.length; index++) {
        let currentFilterCombination = data.main.assets[index].filter
        const currentInputFieldIncluded = currentFilterCombination.includes(currentInputFieldKey)
        let numberOfGroupsWithActiveFilters = 0

        const hasResults = data.main.filter.map((filterGroup, i) => {
          //for each group of filters creates an array of the active filters it's currently holding:
          const activeFiltersInGroup = filterGroup.values
            .map((filterGroupValue) => {
              if (
                checkedFieldKeys.includes(filterGroupValue.key)
                // && filterGroup.key !== currentInputFieldGroup
              ) {
                numberOfGroupsWithActiveFilters += 1
                return filterGroupValue.key
              } else return null
            })
            .filter((el) => el)

          if (activeFiltersInGroup.some((key) => currentFilterCombination.includes(key))) {
            return true
          } else return false
        })

        //check if current filter combination has the same amount of active filters as there is groups with active filters
        //& if the current input field is included

        if (
          hasResults.filter((hasResult) => hasResult === true).length ===
            cleanedCheckedFieldGroups.length &&
          currentInputFieldIncluded
        ) {
          inputField.disabled = false
          break
        } else {
          if (currentInputFieldGroup !== changedFilterGroup) {
            inputField.disabled = true
          }
        }
      }
    })
  }

  const resetFilters = () => {
    setUserFilter(Array(data.main.filter.length).fill([]))
  }

  return (
    <div>
      <NextSeo
        title={data.title}
        description={data.meta_description}
        additionalMetaTags={[{ name: 'title', content: data.meta_title }]}
      />
      <div className="mb-8 px-4 md:mb-16 md:px-0">
        {data.main.banner_bild && (
          <div className="relative min-h-[200px] md:mb-10">
            {data.main.banner_titel ? (
              <Headline
                type={HeadlineType.h1}
                className="px-4 py-8 text-4xl text-white md:p-20 md:text-6xl"
              >
                {data.main.banner_titel}
              </Headline>
            ) : null}
            {data.main.banner_bild.url ? (
              <Image
                src={data.main.banner_bild.url}
                alt={data.main.banner_bild.alt}
                fill
                style={{ objectFit: 'cover' }}
                className="-z-10"
              />
            ) : null}
          </div>
        )}
        {data.breadcrumb && data.breadcrumb.length >= 1 && (
          <Breadcrumb breadcrumbData={data.breadcrumb} pageTitle={data?.title} />
        )}
        <Headline
          type={HeadlineType.h2}
          className="mb-4 mt-10 text-3xl font-medium text-red md:px-20 md:text-5xl"
        >
          {data.main.titel && data.main.titel}
        </Headline>
        <Headline type={HeadlineType.h3} className="text-base font-bold  md:px-20">
          {data.main.untertitel}
        </Headline>
        <div
          className="md:px-20"
          dangerouslySetInnerHTML={{ __html: htmlContent(data.main.text) }}
        />
      </div>
      <div className="mx-4 md:mx-20">
        {data.main.filter ? (
          <Filter
            filter={data.main.filter}
            handleFilters={handleFilters}
            resetFilters={resetFilters}
            hasNoFilters={hasNoFilters}
          />
        ) : null}
        <ul className="mb-10 border-y border-gray py-7 transition duration-200 ease-in-out md:py-10">
          {data.main.assets?.map((asset, i) => {
            const sortedFilters = userFilter.map((f, i) => {
              if (f.length === 0) return true
              else if (asset.filter.some((f: string) => userFilter[i].includes(f))) {
                return true
              } else return false
            })
            if (hasNoFilters || !sortedFilters.includes(false)) {
              return (
                <li
                  key={`${asset.id}_${i}`}
                  className="mb-8 py-4 hover:bg-gray-light md:grid md:grid-cols-[1fr,auto] md:grid-rows-1"
                >
                  <Headline type={HeadlineType.h4} className="mb-0 text-base">
                    {asset.title}
                  </Headline>

                  <div className="flex items-center justify-between">
                    {asset?.url ? (
                      <div className="relative mr-10 h-auto w-[40px]">
                        <Image
                          src={asset?.url}
                          alt={asset?.filename}
                          layout="responsive"
                          width={1}
                          height={1}
                          objectFit="contain"
                          sizes={'40px'}
                        />
                      </div>
                    ) : null}
                    {asset.mimetype || asset.filesize ? (
                      <div className="text-xs md:mr-4 md:w-[8.5rem] md:border-x md:border-gray md:py-1 md:text-center">
                        {asset.mimetype} <br /> {asset.filesize}
                      </div>
                    ) : null}
                    <div className="flex items-center">
                      <Icon name="download" className="mr-2 text-red" />

                      <a
                        href={asset.url}
                        download={asset.filename}
                        className="block font-bold uppercase text-red underline"
                      >
                        {t('Download')}
                      </a>
                    </div>
                  </div>
                </li>
              )
            }
          })}
        </ul>
      </div>
    </div>
  )
}

export default Downloads
