import { useState, useRef, useEffect } from 'react'
import Image from '@next/image'
import { Link } from '../../elements/Link/Link'
import { TextLink, TextLinkTheme } from '../../elements/TextLink/TextLink'
import { Headline, HeadlineType } from '../../elements/Headline/Headline'
import { ReactNode } from 'react'
import { ImageType } from 'typings/image'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useMedia } from 'hooks/useMedia'

export type Props = {
  image: ImageType
  headline: string
  rich_text: string
  href: string
  className?: string
  children?: ReactNode
  path?: string
  linkText?: string
}

export const SmallTeaser = ({
  image,
  headline,
  rich_text,
  href,
  className = '',
  children,
  path,
  linkText,
}: Props) => {
  const [showMore, setShowMore] = useState(false)
  const [isTextClamped, setIsTextClamped] = useState(false)
  const { t } = useTranslation('common')
  const textfield = useRef<HTMLDivElement>(null)
  const isDesktop = useMedia('md')

  useEffect(() => {
    if (!textfield.current || isDesktop) return
    if (
      textfield?.current?.children?.[0] ?
      textfield.current.children[0].scrollHeight > textfield.current.children[0].clientHeight  :
      textfield.current.scrollHeight > textfield.current.clientHeight
    ) {
      setIsTextClamped(true)
    }
  }, [])

  return (
    <div
      className={clsx(
        'mb-4 flex h-full border border-gray md:mx-4 md:mb-0 md:block md:border-0 md:pb-4',
        className && className,
      )}
    >
      <div className="relative aspect-square w-1/3 p-4 pr-0 md:w-full md:p-0">
        {image ? (
          href && image.url ? (
            <Link href={href}>
              <Image
                src={image.url}
                alt={image.alt}
                fill
                style={{
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
                className="w-full"
                sizes="(max-width: 768px) 100vw; 33vw"
              />
            </Link>
          ) : (
            <Image
              src={image.url}
              alt={image.alt}
              fill
              style={{
                objectFit: 'cover',
                objectPosition: 'center',
              }}
              sizes="(max-width: 768px) 100vw; 33vw"
            />
          )
        ) : null}
      </div>
      <div className="w-2/3 p-2 pl-4 md:w-auto md:py-4 md:px-2 lgx:p-4 relative">
        {path ? (
          <Link href={path}>
            <Headline
              type={HeadlineType.h3}
              className="mb-1 text-red md:mb-2 md:text-h3-desk-dynamic"
            >
              {headline}
            </Headline>
          </Link>
        ) : (
          <Headline
            type={HeadlineType.h3}
            className="mb-1 text-red xs:line-clamp-2 md:mb-2 md:text-h3-desk-dynamic md:line-clamp-none"
          >
            {headline}
          </Headline>
        )}
        <div
          ref={textfield}
          dangerouslySetInnerHTML={{ __html: rich_text }}
          className={clsx(
            'richText mb-0 text-sm text-black-light sm:line-clamp-none lg:text-base xl:text-xl',
            showMore || isDesktop ? 'teaser-line-clamp-none' : 'teaser-line-clamp-3',
          )}
        ></div>
        {isTextClamped && !isDesktop ? (
          <button onClick={() => setShowMore((prev) => !prev)} className={'py-2 text-xs underline absolute bottom-0'}>
            {showMore ? t('show less') : t('show more')}
          </button>
        ) : null}
        {children}
        {href && linkText ? (
          <TextLink className="mt-4" href={href} iconSize={12} theme={TextLinkTheme.DARK}>
            {linkText}
          </TextLink>
        ) : null}
      </div>
    </div>
  )
}
