import { SmallTeaser } from 'components/SmallTeaser/SmallTeaser'
import { Headline, HeadlineType } from 'elements/Headline/Headline'
import { Link } from 'elements/Link/Link'
import { usePIMData } from 'hooks/usePIMData'
import { useTranslation } from 'next-i18next'
import { NextSeo } from 'next-seo'
import invariant from 'tiny-invariant'
import { PageType } from 'typings/api'

type Props = { urlKey: string }

export const DownloadsOverview = ({ urlKey }: Props) => {
  const { t } = useTranslation()
  const { data } = usePIMData<PageType>(urlKey)
  invariant(data.type === 'downloads_overview', 'Type was expected to be "downloads"')

  return (
    <div className="mx-auto  py-10">
      <NextSeo
        title={data.title}
        description={data.meta_description}
        additionalMetaTags={[{ name: 'title', content: data.meta_title }]}
      />
      <div className="px-auto container mt-4 mb-6 max-w-lg md:my-14 md:max-w-none md:px-16">
        <Headline
          type={HeadlineType.h1}
          className="mb-5 border-b border-b-gray pb-2 text-3xl text-red md:mx-4 md:mb-10 md:pb-4 md:text-5xl"
        >
          {t('Downloads')}
        </Headline>
        <ul className="grid grid-cols-1 md:grid-cols-3 md:gap-y-8">
          {data.main.map(
            (download) =>
              (download.titel || !!download.link_text?.length) && (
                <li key={download.uuid} className="mb-6 overflow-y-hidden md:mb-0">
                  <SmallTeaser
                    headline={download.titel}
                    href={download.url}
                    image={download.bild}
                    rich_text={download.untertitel}
                  >
                    {!!download.link_text?.length && (
                      <Link href={download.url} className="text-red underline">
                        {download.link_text}
                      </Link>
                    )}
                  </SmallTeaser>
                </li>
              ),
          )}
        </ul>
      </div>
    </div>
  )
}
