import { Props as CMSPageProps } from 'components/PageTypes/CMSPage/CMSPage'
import { Props as PDPProps } from 'components/PageTypes/ProductDetailPage/ProductDetailPage'
import { Props as POPProps } from 'components/PageTypes/ProductOverviewPage/ProductOverviewPage'
import { Spinner } from 'components/Spinner/Spinner'
import { usePIMData } from 'hooks/usePIMData'
import { getDataFromPIM } from 'lib/getDataFromPIM'
import { GetStaticPaths, GetStaticProps, NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { QueryClient } from 'react-query'
import { dehydrate } from 'react-query/hydration'
import { PageType } from 'typings/api'
import { Downloads } from '~/components/Downloads/Downloads'
import { DownloadsOverview } from '~/components/Downloads/DownloadsOverview'
import { checkPageExists } from '~/lib/checkIfPageExistsInRegion'

type Props = { preview: boolean }

const ProductDetailPage = dynamic<PDPProps>(() =>
  import('components/PageTypes/ProductDetailPage/ProductDetailPage').then(
    (mod) => mod.ProductDetailPage,
  ),
)

const ProductOverviewPage = dynamic<POPProps>(() =>
  import('components/PageTypes/ProductOverviewPage/ProductOverviewPage').then(
    (mod) => mod.ProductOverviewPage,
  ),
)

const CMSPage = dynamic<CMSPageProps>(() =>
  import('components/PageTypes/CMSPage/CMSPage').then((mod) => mod.CMSPage),
)

const Page: NextPage<Props> = ({}) => {
  const router = useRouter()
  const { urlKey } = router.query
  const urlString = Array.isArray(urlKey) ? urlKey.join('/') : urlKey
  const { data, isLoading, isError, error } = usePIMData<PageType>(urlString)

  if (isLoading) {
    return <Spinner fullsize />
  }

  if (isError && error instanceof Error) {
    return <div>{error.message}</div>
  }

  switch (data?.type) {
    case 'content_page':
      return <CMSPage identifier={data.path} />
    case 'pdp':
      return <ProductDetailPage urlKey={data.path} />
    case 'pop':
      return <ProductOverviewPage urlKey={data.path} />
    case 'downloads_overview':
      return <DownloadsOverview urlKey={data.path} />
    case 'downloads':
      return <Downloads urlKey={data.path} />
    default:
      return null
  }
}

export const getStaticProps: GetStaticProps = async ({ params, locale, preview = false }) => {
  const queryClient = new QueryClient()
  const locales = ['common', 'prescription-status', 'vendor-search']

  const urlString = Array.isArray(params.urlKey) ? params.urlKey.join('/') : params.urlKey

  await queryClient.prefetchQuery([`/api/backend/${locale}/nav`], () =>
    getDataFromPIM({ url: 'nav', preview, locale }),
  )
  await queryClient.prefetchQuery([`/api/backend/${locale}/footer`], () =>
    getDataFromPIM({ url: 'footer', preview, locale }),
  )
  const pageData = await queryClient.fetchQuery([`/api/backend/${locale}/${urlString}`], () =>
    getDataFromPIM<PageType>({ url: urlString, preview, locale }),
  )

  // if there are no content modules, assume this page is not there
  if (typeof pageData.type === 'undefined' || typeof pageData.main === 'undefined') {
    return { notFound: true }
  }

  await queryClient.fetchQuery(['page-exists'], () =>
    checkPageExists({ url: urlString, locale }).then((result) => {
      return result
    }),
  )

  const { type, main } = pageData
  switch (type) {
    case 'content_page':
      // if there are no content modules, assume this page is not there
      if (main?.content_modules?.length <= 0) {
        return { notFound: true }
      }
      break
    case 'pdp':
      locales.push('pdp')
      break
    case 'pop':
      locales.push('pop')
      break
    case 'downloads_overview':
    case 'downloads':
      break
    default:
      throw new Error(
        `Unexpected type: '${type}' returned from ${`/api/backend/${locale}/${urlString}`}`,
      )
  }

  return {
    revalidate: 10,
    // yes this is weird, but
    // https://github.com/vercel/next.js/discussions/11209#discussioncomment-35915
    props: {
      ...(await serverSideTranslations(locale, locales)),
      preview,
      dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
    },
  }
}

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  }
}

export default Page
